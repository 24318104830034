function antiMailSpam(){
        $("a.email").each(function(){
            var href = $(this).attr('href');
            if(href.indexOf('mailto:')!=-1){
                href = href.replace('|','@');
                var text = $(this).text().replace('|','@');
                $(this).attr('href',href);
                $(this).text(text);                
            }
});

}

(function($) {
    $(document).ready(function($) {  
        antiMailSpam();
        // scroll page service
        if ( $(".video").length ) {
            var top = $('.video').offset().top;
            $(window).scroll(function (event) {
                var y = $(this).scrollTop();
                if (y >= top)
                  $('.video').addClass('sticky');
                else
                  $('.video').removeClass('sticky');
            });
        }
        
         if ( $(".wp-video").length ) {
             
             $(".wp-video").css("width", "100%");
         }
        if(jQuery(".wp-caption")[0]){
            jQuery(".wp-caption").css("width","auto");
        }
        
    // scroll page service
    $('.video h2').on('click', function(evt){
       evt.preventDefault(); 
       // enregistre la valeur de l'attribut  href dans la variable target
        var target = $(this).attr('data');
       /* le sélecteur $(html, body) permet de corriger un bug sur chrome 
       et safari (webkit) */
        $('html, body')
       // on arrête toutes les animations en cours 
       .stop()
       /* on fait maintenant l'animation vers le haut (scrollTop) vers 
        notre ancre target */
       .animate({scrollTop: $(target).offset().top}, 600 );
    });
        
   

        
    });
})(jQuery);

